.insta-feed {
  @extend %normal-top-margin;
}

.insta-feed {
  @include container(8);
}

.insta-thumb-container {
  display: block;
  @include gallery(2 of 8);
  margin-bottom: gutter(8);
  border-bottom: 0;
  
  @include susy-media($mq-huge) {
  	@include gallery(2 of 12);
  	margin-bottom: gutter(12);
  }
  
  &:hover {
    outline: 3px solid white;
  }
}