.pswp-gallery {
  @include container(6);
  width: 100%;
}

.pswp-gallery-thumbs .thumb {
  @include gallery(2 of 6);
  margin-bottom: gutter(6);
  display: none;
  
  &:nth-of-type(1) { display: block; }
  &:nth-of-type(2) { display: block; }
  &:nth-of-type(3) { display: block; }
  &:nth-of-type(4) { display: block; }
  &:nth-of-type(5) { display: block; }
  &:nth-of-type(6) { display: block; }
  
  @include susy-media($mq-xlarge) {
  	@include gallery(2 of 8);
    margin-bottom: gutter(8);
    
    &:nth-of-type(7) { display: block; }
    &:nth-of-type(8) { display: block; }
  }
  
  @include susy-media($mq-giant) {
  	@include gallery(2 of 10);
    margin-bottom: gutter(10);
    
    &:nth-of-type(9) { display: block; }
    &:nth-of-type(10) { display: block; }
  }
}

.pswp-gallery-thumbs .thumb-link img {
  border: 5px solid hsl(0, 0%, 100%);
  //border: 1.25vw solid hsl(0, 0%, 100%);
  
  //@include susy-media($mq-xlarge) {
  //	border: 5px solid hsl(0, 0%, 100%);
  //}
  
  &:hover { border-color:  black }
}

.image-gallery {
  @extend %normal-top-margin;
}

.image-gallery.full-gallery {
  display: none;
}

.show-all-images {
  .pswp-gallery-thumbs .thumb { display: block; }
}