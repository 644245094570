.home-template p {
  @include susy-media($mq-medium) { font-size: 1.2rem; }
  @include susy-media($mq-xlarge) { font-size: 1.4rem; }
  @include susy-media($mq-giant) { font-size: 2rem; font-weight: 300; }
}

.spons-logos {
  @extend %normal-top-margin;
}

.spons-logos .logo-container {
  @include gallery(4 of 8);
  position: relative;
  background-color: white;
  height: 0;
  padding-bottom: 50%;
  margin-bottom: gutter(8);
  
  @include susy-media($mq-huge) {
  	@include gallery(2 of 8);
  	padding-bottom: 25%;
  }
}

.spons-logos .logo-container img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-width: 70%;
  max-height: 70%;
  width: auto;
  height: auto;
  margin: auto;
}

.contact-section {
  font-family: $heading-font-family;
}

.contact-section a {
  border-bottom: 0;
  color: white;
  background-color: transparent;
}

.contact-data-headline {
  color: hsl(0, 0%, 66%);
  margin-top: 1em;
  margin-bottom: 0;
  line-height: 1;
  
  @include susy-media($mq-xlarge) { font-size: 2rem; }
  @include susy-media($mq-huge) { font-size: 3rem; }
}

.home-template .contact-data {
  font-size: 2rem;
  line-height: 1;
  
  @include susy-media($mq-huge) { font-size: 3rem; }
}