.main-header {
  overflow: hidden;
  z-index: 1;
}

.logo-anchor {
  display: block;
  width: 22%;
  max-width: 170px;
  border-bottom: none;
  margin: margin(start);
  @include susy-media($mq-xsmall) { margin: margin(xsmall); }
  @include susy-media($mq-small) { margin: margin(small); }
  @include susy-media($mq-medium) { margin: margin(medium); margin-bottom: 0; }
  @include susy-media($mq-large) { margin: margin(large); margin-bottom: 0; }
  @include susy-media($mq-xlarge) { margin: margin(xlarge); margin-bottom: 0; }
  @include susy-media($mq-huge) { margin: margin(huge); margin-bottom: 0; }
  
  &:hover {
    background-color: transparent;
  }
}

.logo-anchor .logo {
  width: 100%;
  height: auto;
}


.languange-switch {
  position: absolute;
  top: 0;
  right: 0;
  font-family: $heading-font-family;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  text-align: right;
  padding: 10px;
  line-height: 1;
  
  @include susy-media($mq-small) { font-size: 1.2rem; padding: 15px; }
  @include susy-media($mq-large) { font-size: 1.4rem; padding: 25px; }
  
  a {
    color: hsl(0, 0%, 70%);
    border-bottom: 0;
    
    &:hover,
    &:focus { background-color: transparent; border-bottom: 1px solid white; color: white; }
  }
}

.languange-switch li {
  display: inline-block;
  
  &.active a { color: white; }
}

.languange-switch ul li:first-of-type::after {
  content: '/';
  color: hsl(0, 0%, 70%);
}




.om-mig {
  position: relative;
  height: 0;
  padding-bottom: 65%;
  
  //@include susy-media($mq-medium) { padding-bottom: 55%; }
  @include susy-media($mq-large) { padding-bottom: 63%; }
  @include susy-media($mq-xlarge) { padding-bottom: 55%; }
  @include susy-media($mq-huge) { padding-bottom: 45%; }
}

.om-mig h1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  margin-top: 8vw;
  z-index: 2;
  margin-left: margin(start);
  
  @include susy-media($mq-xsmall) { margin-left: margin(xsmall); }
  @include susy-media($mq-small) { margin-left: margin(small); }
  @include susy-media($mq-medium) { margin-left: margin(medium); }
  @include susy-media($mq-large) { margin-left: margin(large); }
  @include susy-media($mq-xlarge) { margin-left: margin(xlarge); margin-top: 6vw; }
  @include susy-media($mq-huge) { margin-left: margin(huge); }
}

.image-placeholder {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 65%;
  z-index: 1;
  overflow: hidden;
  
  @include susy-media($mq-small) { width: 60%; }
  @include susy-media($mq-medium) { width: 55%; }
  @include susy-media($mq-large) { width: 65%; right: -8%; }
  @include susy-media($mq-huge) { width: 50%; right: -3%; }
  
}

.main-image {
  margin-left: 50px;
  @include susy-media($mq-huge) { margin-bottom: -15%;}
  @include susy-media($mq-giant) { margin-bottom: -5%;}
}