body {
  color: color(font-color);
  font-family: $default-font-family;
  font-size: 1rem;
  font-weight: $default-font-weight;
  line-height: $default-line-height;
  -webkit-font-smoothing: antialiased;
}


.black-bg {
  color: #FFFFFF;
}



h1,h2,h3,h4,h5,h6 {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  line-height: $heading-line-height;
  text-transform: uppercase;
  //-webkit-font-smoothing: subpixel-antialiased;
}

h1, h2 {
  text-align: left;
  //font-size: 2.6rem;
  font-size: 3rem;
  font-size: 12vw;
  line-height: 1.05;
  
  @include susy-media($mq-large) { font-size: 11vw; }
  //@include susy-media($mq-xlarge) { font-size: 11vw; }
  @include susy-media($mq-huge) { font-size: 9vw; }
  @include susy-media($mq-giant) { font-size: 8vw; }
}

h1 + h2 { margin-top: 0.8em; }

//h2 {
//  font-size: 2.6rem;
//  font-size: 12vw;
//  font-weight: 700;
//  
//  @include susy-media($mq-medium) { font-size: 11vw; }
//  //@include susy-media($mq-large) { font-size: 4.5rem; }
//  //@include susy-media($mq-xlarge) { font-size: 5rem; }
//  //@include susy-media($mq-huge) { font-size: 5.5rem; }
//}

h2 + p { margin-top: 0.8em; }

h3 { font-size: 1.6rem; }

p + p { margin-top: $default-top-margin}

p {
  font-size: 0.95rem;
  
  @include susy-media($mq-medium) { font-size: 1rem; }
  
  strong, b { font-weight: 700; }
  em, i { font-style: italic; }
}



blockquote {
  position: relative;
  
  p {
    position: relative;
    display: inline;
    padding: 0 0.95em;
    font-size: 1.1rem;
  }
  
  p:before,
  p:after {
    position: absolute;
    font-size: 1.5em;
    line-height: 1;
  }
  
  p:before {
    content: open-quote;
    top: 0;
    left: 0;
  }
  
  p:after {
    content: close-quote;
    bottom: 0;
    right: 0;
  }
}



a {
  color: $default-link-color;
  text-decoration: none;
  border-bottom: 1px solid $default-link-color;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  
  &:hover,
  &:focus {
    background-color: $default-link-color-hover;
  }
}