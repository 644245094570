.videos {
  @extend %normal-top-margin;
  @include container(6);
}

.videos .video {
  margin-bottom: gutter(6);
  
  @include susy-media($mq-large) {
  	float: left;
  	width: span(3 of 6);
  	
  	&:nth-of-type(1) { width: 100%; }
  	&:nth-of-type(even) { margin-right: gutter(6); }
  }
  
  @include susy-media($mq-huge) {
  	&:nth-of-type(1) { width: span(3 of 6); }
  	&:nth-of-type(even) { margin-right: 0; }
  	&:nth-of-type(odd) { margin-right: gutter(6); }
  }
  
  @include susy-media($mq-mega) {
    width: span(2 of 6);
    
  	&:nth-of-type(1) { width: span(2 of 6); }
  	&:nth-of-type(even) { margin-right: gutter(6);; }
  	&:nth-of-type(odd) { margin-right: gutter(6); }
  	&:nth-of-type(3n) { margin-right: gutter(0); }
  }
}

.video-section .video iframe {
  width: 100%;
}