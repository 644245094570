//----------------------------------*\
// MEDIA QUERIES
//----------------------------------*/
$mq-xsmall: 20em; //320px
$mq-small: 25em; //400px
$mq-medium: 31.25em; //500px
$mq-large: 40.625em; //650px
$mq-xlarge: 53.125em;  //850px
$mq-huge: 65.625em; //1050px'
$mq-giant: 75em; //1200px
$mq-mega: 87.5em; //1400px

$mq-large-only: $mq-large 53.0625em;
$mq-on-canvas-menu: $mq-xlarge;


//----------------------------------*\
// COLORS
//----------------------------------*/
$colors: (
  bg-color: hsl(0, 0%, 0%),
  font-color: hsl(0, 0%, 0%),
);



//----------------------------------*\
// MARGINS ANS PADDINGS
//----------------------------------*/
$side-margins: (
  start: 15px,
  xsmall: 20px,
  small: 25px,
  medium: 50px,
  large: 50px,
  xlarge: 70px,
  huge: 150px,
);

$default-top-margin: 1.6em;



//----------------------------------*\
// DEFAULT FONT SETTINGS
//----------------------------------*/
$default-font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$default-line-height: 1.6;
$default-font-color: hsl(0, 0%, 15%);
$default-font-weight: 400;
$default-link-color: hsl(351,72%,44%);
$default-link-color-hover: mix(white, $default-link-color, 30%);




//----------------------------------*\
// HEDINGS FONT SETTINGS
//----------------------------------*/
$heading-font-family: 'trumpgothicpro', 'Helvetica', sans-serif;
$heading-line-height: 1.1;
$heading-color: $default-font-color;
$heading-font-weight: 700;



//----------------------------------*\
// SUSY SETTINGS
//----------------------------------*/
$susy: (
  flow: ltr,
  math: fluid,
  output: float,
  gutter-position: after,
  //container: 1100px,
  container-position: center,
  columns: 12,
  gutters: .20,
  column-width: false,
  global-box-sizing: border-box,
  last-flow: to,
  debug: (
    image: hide, //show / hide
    color: rgba(#66f, .25),
    output: background, //background / overlay
    toggle: top left,
  ),
);