*, *:after, *:before {
  position: relative;
  box-sizing: border-box;
  //font-smoothing:antialiased;
  //text-rendering:optimizeLegibility;
  -webkit-font-variant-ligatures: normal;
  font-variant-ligatures: normal;
  -moz-font-feature-settings: "kern=1"; /* pre-Firefox 14+ */
  -webkit-font-feature-settings: "kern";
  -moz-font-feature-settings: "kern"; /* Firefox 14+ */
  font-feature-settings: "kern"; /* standard */
  font-kerning: normal; /* Safari 7+, Firefox 24+, Chrome 33(?)+, Opera 21+*/
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
}

html {
  font-size:100%;
  height: auto;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

body {
  height: auto;
  min-height: 100%;
  background-color: color(bg-color);
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.viewport {
  min-height: 100%;
  height: auto;
}

ul {
  list-style: none;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
  }
}

.dark-bg {
  background-color: hsl(0, 0%, 0%);
  color: hsl(0, 0%, 100%);
}

.dark-bg h1,
.dark-bg h2,
.dark-bg h3,
.dark-bg p {
  color: hsl(0, 0%, 100%);
}

.brown-bg {
  background-color: hsl(39, 18%, 77%);
}

.white-bg {
  background-color: hsl(0, 0%, 100%);
}

.black-bg {
  background-color: hsl(0, 0%, 0%);
}

.devider-angle {
  position: relative;
  padding-bottom: 7.6%; // 38 / 500, backgrundens höjd genom bredd
  background-repeat: no-repeat;
  background-size: 110%; // lite förstor bakgrund för att inte riskera misspadd
  margin-top: -7%; // lite mindre än padding-bottom för att inte riskera att få misspass
  background-position: -10px 0; // -10px för att inte riskera misspass
  z-index: 10;
  
  &.brown { background-image: url(../images/background-brown-angle.svg); }
  &.white { background-image: url(../images/background-white-angle.svg); }
  &.black { background-image: url(../images/background-black-angle.svg); }
}