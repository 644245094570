%normal-top-margin {
  margin-top: span(1 of 12);
}

%side-margins {
  padding: 0 margin(start);
  @include susy-media($mq-xsmall) { padding: 0 margin(xsmall); }
  @include susy-media($mq-small) { padding: 0 margin(small); }
  @include susy-media($mq-medium) { padding: 0 margin(medium); }
  @include susy-media($mq-large) { padding: 0 margin(large); }
  @include susy-media($mq-xlarge) { padding: 0 margin(xlarge); }
  @include susy-media($mq-huge) { padding: 0 margin(huge); }
}

.grid-container {
  @include container(12);
}

.content {
  @extend %side-margins;
  padding-top: span(3 of 24 wider);
  padding-bottom: span(4 of 24 wider);
  
  @include susy-media($mq-huge) {
  	padding-top: span(2 of 24 wider);
    padding-bottom: span(3 of 24 wider);
  }
}