.btn {
  display: inline-block;
  padding: 0.3em 1.5em;
  border: 2px solid color(font-color);
  border-radius: 3px;
  color: color(font-color);
  font-family: $heading-font-family;
  font-size: 1.3em;
  margin: 0 auto;
  text-transform: uppercase;
  
  &:hover,
  &:focus {
    background-color: black;
    color: white;
  }
  
  @include susy-media($mq-large) { font-size: 1.5rem; }
  @include susy-media($mq-huge) { font-size: 1.7rem; }
}

.btn-container {
  @extend %normal-top-margin;
  text-align: center;
  
  btn {
    margin: 0 auto;
  }
}